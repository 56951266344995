<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <div class="h1">
        新疆维吾尔自治区放射专业质量控制中心成功举办<br />
        “质”同道合、“影”领未来放射质控研讨会
      </div>
      <section>
        <div class="div_p">
          为深化放射专业医疗质量管理，规范影像诊疗行为，推动医疗服务规范化、同质化，实现放射专业医疗质量和服务水平的持续改进。2024年11月9日，自治区放射专业质量控制中心在新疆医科大学第一附属医院成功举办了
          “质”同道合、“影”领未来放射质控线上线下研讨会。
        </div>
        <div>
          <img src="@/assets/HY20241114/1.png" alt="">
        </div>
        <div class="div_p">
          新疆医科大学第一附属医院影像中心副主任(主持工作)王云玲教授代表新疆医学会放射专委会，新疆生产建设兵团医院医学影像科主任马静教授代表兵团医学会放射学分会，北京协和医院西院放射科主任兼放射科副主任薛华丹教授代表国家放射影像专业医疗质量控制中心及疆外专家代表在开幕式上分别致辞。
        </div>
        <div>
          <img src="@/assets/HY20241114/2.png" alt="">
          <img src="@/assets/HY20241114/3.png" alt="">
        </div>
        <div class="div_p">
          首先，薛华丹教授分享了《放射影像质控指标内涵和建立流程》的讲座。薛华丹教授针对“全国放射影像质控指标(国七条)”进行了深入的传达与解读，并且分析了基于全国范围内囊括二级及以上医疗机构的质控大数据，高屋建瓴的指标解读及全面多样的数据分析让与会人员更深入地了解了质控指标制定的意义及实施方案，了解了国内放射专业的现状及努力方向，线上线下与会人员均收益匪浅。
        </div>
        <div>
          <img src="@/assets/HY20241114/4.png" alt="">
        </div>
        <div class="div_p">
          福建省放射诊断质控中心主任、福州大学附属省立医院放射科主任马明平教授在研讨会上进行了《2024年放射质量安全改进目标——福建省放射质控经验》的分享。马明平教授分别从“放射科急救能力”“影像报告质控”“疑难病例分析和读片会制度”“信息化临床及随访系统”等多方面详细阐述了福建省的质控经验，马明平教授关于“信息化系统建设”的经验分享触发了与会人员的广泛兴趣与激烈讨论。
        </div>
        <div class="div_p">
          本次研讨会上，昌吉州人民医院放射影像中心武方明主任，新疆医科大学第一附属医院昌吉分院放射科邹振宇主任，喀什地区第一人民医院影像中心邱娅副主任分别围绕质控进行讲解和经验分享，专题涉及《如何做好基层医院的放射质控》、《MR扫描的伪影分析》、《PDCA提高多民族聚居地区影像学检查质量》。
        </div>
        <div class="div_p">
          研讨会专门安排了讨论环节，讨论环节异常热烈，线上及线下与会人员分别就各单位放射专业质控方案的落实及近期遇到的疑惑向薛华丹教授、马明平教授请教，两位教授耐心倾听大家的问题并给出积极响应。线上线下专家们交流心得、分享经验，收获满满。
        </div>
        <div>
          <img src="@/assets/HY20241114/5.png" alt="">
        </div>
        <div class="div_p">
          本次研讨会由自治区放射质控中心主任、新疆医科大学第一附属医院影像中心副主任邢艳教授主持，自治区放射专业质控专家共同参与，线上线下会议同步进行。新疆维吾尔自治区放射质控研讨会旨在增强新疆地区影像专业质控的培训和指导，有效促进各地州县市医疗机构质控分中心构建与质量管理工作的落实，切实提升质控管理的科学化与精细化水平，保障并推动我区医学影像专业的学科发展，为各族群众提供优质高效的医学影像服务奠定坚实基础。
        </div>


      </section>
    </div>

  </div>
</template>

<script>
export default {
name:"HY20241114",
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
.vertical-img img {
  width: 50% !important;
  display: inline-block;
}
</style>